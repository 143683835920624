<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الحجوزات</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">الحجوزات</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="my-invoices">
                                <div class="inner-container clearfix">
                                    <div class="invoice-box">
                                        <div class="table-responsive">
                                            <table
                                                id="reservations-table"
                                                class="table table-striped table-bordered table-hover"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>عنوان العقار</th>
                                                        <th>الصورة</th>
                                                        <th>تاريخ الطلب</th>
                                                        <th>الدخول</th>
                                                        <th>الخروج</th>
                                                        <th>حفلة</th>
                                                        <th>الإجمالى</th>
                                                        <th>التقييم</th>
                                                        <th>الحالة</th>
                                                        <th>
                                                            <i
                                                                class="la la-cog"
                                                            ></i>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal -->
                        <div
                            class="modal fade"
                            id="payment-modal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modelTitleId"
                            aria-hidden="true"
                            data-backdrop="false"
                        >
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">دفع</h5>
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true"
                                                >&times;</span
                                            >
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <iframe
                                            class="payment-iframe"
                                            v-if="paymentUrl"
                                            :src="paymentUrl"
                                        ></iframe>
                                    </div>
                                    <div class="modal-footer">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/plugins/datatables";
import $ from "jquery";
import "bootstrap";

export default {
    name: "my.reservations",
    data() {
        return {
            table: null,
            paymentUrl: null,
        };
    },
    mounted() {
        this.prepareTable();
        $("#payment-modal").on("hidden.bs.modal", (e) => {
            this.paymentUrl = null;
            this.updateTable();
        });
    },
    methods: {
        actions(row) {
            switch (row.status) {
                case "pending":
                    return this.paymentButton(row) + this.cancelButton(row);
                    break;
                case "authorized":
                    return this.cancelButton(row);
                    break;
                case "accepted":
                    return this.cancelButton(row, true);
                    break;
                case "request_cancel":
                    return this.cancelCancelationButton(row, true);
                    break;

                default:
                    return "---";
                    break;
            }
        },
        paymentButton(row) {
            return `<button class="btn btn-primary m-1" v-on="click, redirectToPayment(${row.id})" ><i class="la la-dollar" ></i></button>`;
        },
        cancelButton(row, confirm = false) {
            return `<button class="btn btn-warning m-1" v-on="click, cancelReservation(${row.id}, ${confirm})" ><i class="la la-times-circle" ></i></button>`;
        },
        cancelCancelationButton(row) {
            return `<button class="btn btn-success m-1" v-on="click, cancelReservationCancelationRequest(${row.id})" ><i class="la la-check-circle" ></i></button>`;
        },
        cancelReservationCancelationRequest(reservation_id) {
            this.$axios
                .delete("/my/cancelation-requests/" + reservation_id)
                .then(() => {
                    this.updateTable();
                });
        },
        redirectToPayment(reservation_id) {
            this.$axios
                .post("/my/transaction-request/" + reservation_id)
                .then((res) => {
                    this.paymentUrl = res.data.url;
                    $("#payment-modal").modal("show");
                });
        },
        cancelReservation(reservation_id, confirm = false) {
            if (
                !confirm ||
                window.confirm(
                    "سيتم ارسال طلب لإلغاء الحجز إلى الادارة وفى حال الموافقة سيتك خصم جزء من قيمة الحجز وإرجاع الباقى ؟"
                )
            ) {
                this.$axios
                    .delete("/my/reservations/" + reservation_id)
                    .then(() => {
                        this.updateTable();
                    });
            }
        },
        prepareTable() {
            this.table = $("#reservations-table")
                .DataTable({
                    ajax: {
                        url:
                            process.env.VUE_APP_API_BASE_URL +
                            "/my/reservations",
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                        beforeSend: () => {
                            this.$loading.show();
                        },
                    },
                    serverSide: true,
                    stateSave: true,
                    columns: [
                        { data: "id" },
                        { data: "title" },
                        {
                            data: "property.image_url",
                            render: (image_url) => {
                                return `<img class="img-thumbnail" src="${image_url}" />`;
                            },
                            searchable: false,
                            orderable: false,
                        },
                        { data: "created_at" },
                        {
                            name: "from_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.from_day +
                                    " " +
                                    (row.from_period +
                                        " " +
                                        (row.from_period == 1
                                            ? "فترة أولى"
                                            : row.from_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            name: "to_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.to_day +
                                    " " +
                                    (row.to_period +
                                        " " +
                                        (row.to_period == 1
                                            ? "فترة أولى"
                                            : row.to_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            data: "party",
                            render: (party) => {
                                switch (party) {
                                    case "birthday":
                                        return "عيد ميلاد";
                                        break;
                                    case "wedding":
                                        return "زفاف";
                                        break;
                                    case "other":
                                        return "أخرى";
                                        break;
                                    default:
                                        return "لا يوجد";
                                        break;
                                }
                            },
                        },
                        { data: "total" },
                        {
                            data: "property.average_overall_rating",
                            render: (rating) => {
                                return (
                                    `<div class="rating text-info">
                                    <span class="` +
                                    (rating >= 1
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 2
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 3
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 4
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `" ></span>
                                        <span class="` +
                                    (rating >= 5
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `" ></span>
                                    </div>`
                                );
                            },
                        },
                        {
                            data: "status",
                            render: (status) => {
                                switch (status) {
                                    case "pending":
                                        return "بانتظار تأكيد الدفع";
                                        break;
                                    case "authorized":
                                        return "تم تأكيد الدفع";
                                        break;
                                    case "accepted":
                                        return "تم الموافقة من المعلن وسحب المبلغ";
                                        break;
                                    case "request_cancel":
                                        return "تم طلب الإلغاء من الإدارة";
                                        break;
                                    case "canceled":
                                        return "تم الموافقة على الإلغاء";
                                        break;
                                    case "finished":
                                        return "إنتهى";
                                        break;
                                    case "rated":
                                        return "تم التقييم";
                                        break;
                                }
                            },
                        },
                        {
                            data: null,
                            render: (row) => {
                                return this.actions(row);
                            },
                            searchable: false,
                            orderable: false,
                        },
                    ],
                })
                .on("draw", () => {
                    this.$loading.hide(0);
                    $("[v-on]").each((index, element) => {
                        let ob = ((attr) => ({
                            event: /(\w+),/.exec(attr)[1],
                            handler: /, ?(.*)/.exec(attr)[1],
                        }))($(element).attr("v-on"));
                        let f = /(\w+)\(?/.exec(ob.handler)[1];
                        let args = /\w\((.*)\)/
                            .exec(ob.handler)[1]
                            .split(",")
                            .map((v) => v.trim());
                        $(element).on(ob.event, () => {
                            this[f].apply(this, args);
                        });
                    });
                });
        },
        updateTable() {
            this.table.ajax.reload(null, false);
        },
    },
};
</script>

<style scoped>
.payment-iframe {
    width: 100%;
    height: 500px;
}
</style>
<style>
@import "https://cdn.datatables.net/1.10.22/css/jquery.dataTables.min.css";
</style>